import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="mobile-products-view"
export default class extends Controller {
  toggle_view(event) {
    const activeElements = document.querySelectorAll('.active');
    activeElements.forEach(element => { element.classList.remove('active') });
    event.currentTarget.classList.add('active');

    var productsContainer = document.getElementById('products');
    var firstViewItem = document.querySelector('.view-item.list');
    if (firstViewItem.classList.contains('active')) {
      productsContainer.classList.add('grid');
    } else {
      productsContainer.classList.remove('grid');
    }
  }
}
