import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ['size', 'additionText'];

  updateSize(event) {
    const lineItemId = event.params.index;
    const sizeId = this.sizeTarget.value

    var sizeText = this.sizeTarget.options[this.sizeTarget.selectedIndex].text;
    if (sizeText === 'By individual measurement' || sizeText === 'За індивідуальними мірками') {
      this.additionTextTarget.classList.remove('hidden');
    } else {
      this.additionTextTarget.classList.add('hidden');
    }

    Rails.ajax({
      type: "PATCH",
      url: `/contacts/change_line_item`,
      data: `size_id=${sizeId}&id=${lineItemId}`,
      error: (error) => {
        console.error(error);
      }
    });
  }
}
