import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="filter-dropdown"
export default class extends Controller {
  connect() {
    var filterItemTexts = document.querySelectorAll('.filter-item-text');
    // var sortBy = document.querySelector('.sort_by');

    filterItemTexts.forEach(function(filterItemText) {
      filterItemText.addEventListener('click', function(event) {
        document.querySelectorAll('.filter-item').forEach(function(otherFilterItem) {
          if (!otherFilterItem.contains(event.target)) {
            otherFilterItem.classList.remove('active');
          }
        });

        filterItemText.parentElement.classList.toggle('active');
      });
    });

    document.addEventListener('click', function(event) {
      if (!event.target.matches('.filter-item, .filter-item *')) {
        document.querySelectorAll('.filter-item').forEach(function(filterItem) {
          filterItem.classList.remove('active');
        });
      }
    });

    // sortBy.addEventListener('click', function() {
    //   sortBy.classList.toggle('active');
    // })

    var filterMobileButton = document.querySelector('.mobile-btn-filter');
    var filterMenu = document.querySelector('.filters-box');

    if (window.innerWidth < 801) {
      filterMobileButton.addEventListener('click', function() {
        filterMenu.classList.toggle('active');
      });
    }
  }
}
