import { Controller } from "@hotwired/stimulus"
import { validateBusinessForm } from '../packs/validation';

// Connects to data-controller="custom-captcha"
export default class extends Controller {
  static targets = [ 'canvas', 'hiddenCaptchaInput', 'inputCaptcha' ]

  connect() {
    function getRandomInt(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    // Get canvas element and context
    var canvas = this.canvasTarget;
    var ctx = canvas.getContext('2d');

    // Array of numbers
    var numbers = [];

    for (var i = 0; i < 8; i++) {
      var randomNumber = getRandomInt(0, 9); // Generate a random number between 0 and 9
      numbers.push(randomNumber); // Push the random number into the array
    }

    window.capchaNumbers = numbers.slice(2, 6).map(String).join('');
    var hidden_captcha_input = this.hiddenCaptchaInputTarget;
    hidden_captcha_input.value = ((parseInt(window.capchaNumbers, 10) * 2 + 11) * 7 + 11).toString();

    // Font properties
    var fontSize = 25;
    ctx.font = fontSize + 'px Arial';
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';

    // Loop through numbers
    for (var i = 0; i < numbers.length; i++) {
        var x = i * (fontSize * 2)+10; // Adjust spacing
        var y = canvas.height / 2;

        // Check if the number index is between 2 and 5
        if (i >= 2 && i <= 5) {
            // Draw gray background
            ctx.fillStyle = '#CCCCCC';
            ctx.fillRect(x - fontSize, y - fontSize, fontSize * 2, fontSize * 2);
        }

        // Draw number
        ctx.fillStyle = '#000000';
        ctx.fillText(numbers[i], x, y);
    }

    var button = this.buttonSendTarget;

    var captchaValue = this.inputCaptchaTarget;

    captchaValue.addEventListener('input', function() {
      if (captchaValue.value === numbers.slice(2, 6).join('')) {
        captchaValue.style.borderColor = "green";
        validateBusinessForm();
      } else {
        captchaValue.style.borderColor = "red";
        button.style.opacity = "0.4";
        button.style.pointerEvents = "none";
      }
    })
  }
}

