import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="filter-form"
export default class extends Controller {
  static targets = ["sortBySelect", "presenceSelect"];

  connect() {
    // Find all checkboxes within the form
    const checkboxes = this.element.querySelectorAll('input[type="checkbox"]');

    // Attach an event listener to each checkbox
    checkboxes.forEach((checkbox) => {
      checkbox.addEventListener("change", this.handleCheckboxChange.bind(this));
    });
  }

  handleCheckboxChange() {
    // Get the form element
    const form = this.element;

    let formData = new FormData(form);
    formData.delete("authenticity_token");
    let formDataUrl = new URLSearchParams(formData).toString();
    const urlWithoutParams = window.location.origin + window.location.pathname;
    const newFilteredUrl = `${urlWithoutParams}?${formDataUrl}`
    window.history.replaceState({}, document.title, newFilteredUrl)

    // Submit the form when any checkbox is checked
    form.requestSubmit();
  }

  remove_filter_rule(event) {
    // remove param of filter from url
    const { id, slug, klass } = event.params;
    const currentUrl = new URL(window.location.href);
    const existingFilterValues = currentUrl.searchParams.getAll(`filter[${klass}][]`);
    const updatedFilterValues = existingFilterValues.filter(value => value !== slug.toString());
    currentUrl.searchParams.delete(`filter[${klass}][]`);
    updatedFilterValues.forEach(value => currentUrl.searchParams.append(`filter[${klass}][]`, value));

    // Redirect to the updated URL
    window.history.replaceState({}, document.title, currentUrl.href);

    // removed checked from class filter
    document.getElementById(`${klass}${id}`).checked = false;
  }

  remove_all_filter_rules() {
    const filterElement = document.querySelector('.filter');

    if (filterElement) {
      const checkboxes = filterElement.querySelectorAll('input[type="checkbox"]');
      checkboxes.forEach((checkbox) => { checkbox.checked = false });
    }

    let updatedUrl = window.location.origin + window.location.pathname;
    window.history.replaceState({}, document.title, updatedUrl);
  }

  change_sort_by() {
    const form = this.element;
    const selectedValue = this.sortBySelectTarget.value;

    // Update window URL with the new sort_by parameter
    let currentUrl = new URL(window.location.href);
    currentUrl.searchParams.set("filter[sort_by]", selectedValue);

    // Remove the sort_by filter if the user selects "None"
    if (selectedValue == "") {
      currentUrl.searchParams.delete("filter[sort_by]");
    }

    // Redirect to the updated URL
    window.history.replaceState({}, document.title, currentUrl.href);

    // Submit the form when sort_by is selected
    form.requestSubmit();
  }

  change_presence() {
    const form = this.element;
    const selectedValue = this.presenceSelectTarget.value;

    // Update window URL with the new sort_by parameter
    let currentUrl = new URL(window.location.href);
    currentUrl.searchParams.set("filter[presence]", selectedValue);

    // Remove the presence filter if the user selects "All"
    if (selectedValue == "") {
      currentUrl.searchParams.delete("filter[presence]");
    }

    // Redirect to the updated URL
    window.history.replaceState({}, document.title, currentUrl.href);

    // Submit the form when sort_by is selected
    form.requestSubmit();
  }
}

