import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="multi-pictures-upload-helper"
export default class extends Controller {
  static targets = ["filesPreview"];

  showPreview(event) {
    const files = event.target.files;

    if (files.length > 0) {
      const previewTarget = this.filesPreviewTarget;
      previewTarget.innerHTML = "";

      for (const file of files) {
        const img = document.createElement("img");
        img.src = URL.createObjectURL(file);
        img.alt = "Uploaded Image";
        img.style = "height: 200px"

        previewTarget.appendChild(img);
      }
    }
  }
}
