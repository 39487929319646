import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "select"]

  search() {
    const query = this.inputTarget.value.toLowerCase()
    const options = this.selectTarget.options

    for (let i = 0; i < options.length; i++) {
      const option = options[i]
      if (option.text.toLowerCase().includes(query)) {
        option.style.display = "block"
      } else {
        option.style.display = "none"
      }
    }
  }
}
