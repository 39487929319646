import { Controller } from "@hotwired/stimulus"


// Connects to data-controller="select-order-service"
export default class extends Controller {
  static targets = ["input"]

  connect() {
    $(this.inputTarget).select2({
      placeholder: this.data.get('placeholder')
    });

    $(this.inputTarget).on('select2:open', function () {
      $('.select2-search--dropdown').hide();
    });
  }
}
