import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="single-checkbox-selection"
export default class extends Controller {
  connect() {
    this.element.querySelectorAll("input[type='checkbox']").forEach((checkbox) => {
      checkbox.addEventListener("change", () => this.handleCheckboxChange(checkbox));
    });
  }

  handleCheckboxChange(checkedCheckbox) {
    if (checkedCheckbox.checked) {
      this.element.querySelectorAll("input[type='checkbox']").forEach((checkbox) => {
        if (checkbox !== checkedCheckbox) {
          checkbox.checked = false;
        }
      });
    }
  }
}
