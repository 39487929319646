import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="readmore"
export default class extends Controller {
  connect(e) {
    var readMoreButton = document.querySelector('.read-more');

    if (readMoreButton) {
      var hiddenText = document.querySelector('.choose-text');
      var hideMoreButton = document.querySelector('.make-hide');

      readMoreButton.addEventListener('click', function() {
        setTimeout(function () {
          hiddenText.classList.remove('hidden');
          readMoreButton.style.display = "none";
          hideMoreButton.style.display = "block";
        }, 800);
      });

      hideMoreButton.addEventListener('click', function() {
        setTimeout(function () {
          hiddenText.classList.add('hidden');
          readMoreButton.style.display = "block";
          hideMoreButton.style.display = "none";
        }, 800);
      });
    }
  }
}
