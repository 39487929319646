import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["priceInput"];

  toggleSizesGroupActive(event) {
    const sizesGroupId = event.params.index;

    Rails.ajax({
      type: "patch",
      url: `sizes_groups/${sizesGroupId}`,
      data: `target=active`
    });
  }

  toggleSizeActive(event) {
    const sizeId = event.params.index;

    Rails.ajax({
        type: "patch",
        url: `sizes/${sizeId}`,
        data: `target=active`,
    });
  }

  updatePriceIncrease(event) {
    const newPriceIncrease = this.priceInputTarget.value;
    const sizeId = event.params.index;

    Rails.ajax({
      type: "patch",
      url: `sizes/${sizeId}`,
      data: `target=price_increase&price_increase=${newPriceIncrease}`,
    });
  }
}
