import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input"]

  searchRelatedProducts(event) {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
      const searchTerm = this.inputTarget.value.trim()

      const formData = new FormData();
      formData.append('search_term', searchTerm);

      let search_related_path = window.location.origin + "/admin/products/search_related"
      fetch(search_related_path, {
        method: 'POST',
        headers: {
          'X-CSRF-Token': csrfToken,
          'Accept': 'text/vnd.turbo-stream.html'
        },
        body: formData
      })
      .then(response => response.text())
      .then(turboStream => {
        document.querySelector('#search_related_products').innerHTML = turboStream;
      })
    }, 500)
  }

  inputRelatedFocusout(event) {
    if (document.getElementById('search_related_controller').contains(event.relatedTarget)) {
      return;
    }

    document.getElementById('search_related_products').classList.remove('openBox');
  }

  inputRelatedOnfocus() {
    const inp = document.getElementById('related-search-input');

    if (inp.value.length > 0) {
      document.getElementById('search_related_products').classList.add('openBox');
    }
  }

  //--------------------------------------------------------------------------

  searchEnsembleProducts(event) {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
      const searchTerm = this.inputTarget.value.trim()

      const formData = new FormData();
      formData.append('search_term', searchTerm);

      let search_ensemble_path = window.location.origin + "/admin/products/search_ensemble"
      fetch(search_ensemble_path, {
        method: 'POST',
        headers: {
          'X-CSRF-Token': csrfToken,
          'Accept': 'text/vnd.turbo-stream.html'
        },
        body: formData
      })
      .then(response => response.text())
      .then(turboStream => {
        document.querySelector('#search_ensemble_products').innerHTML = turboStream;
      })
    }, 500)
  }

  inputEnsembleFocusout(event) {
    if (document.getElementById('search_ensemble_controller').contains(event.relatedTarget)) {
      return;
    }

    document.getElementById('search_ensemble_products').classList.remove('openBox');
  }

  inputEnsembleOnfocus() {
    const inp = document.getElementById('ensemble-search-input');

    if (inp.value.length > 0) {
      document.getElementById('search_ensemble_products').classList.add('openBox');
    }
  }

  //--------------------------------------------------------------------------

  searchWeddingProducts(event) {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
      const searchTerm = this.inputTarget.value.trim()

      const formData = new FormData();
      formData.append('search_term', searchTerm);

      let search_wedding_path = window.location.origin + "/admin/home/search_wedding"
      fetch(search_wedding_path, {
        method: 'POST',
        headers: {
          'X-CSRF-Token': csrfToken,
          'Accept': 'text/vnd.turbo-stream.html'
        },
        body: formData
      })
      .then(response => response.text())
      .then(turboStream => {
        document.querySelector('#search_wedding_products').innerHTML = turboStream;
      })
    }, 500)
  }

  inputWeddingFocusout(event) {
    if (document.getElementById('search_wedding_controller').contains(event.relatedTarget)) {
      return;
    }

    document.getElementById('search_wedding_products').classList.remove('openBox');
  }

  inputWeddingOnfocus() {
    const inp = document.getElementById('wedding-search-input');

    if (inp.value.length > 0) {
      document.getElementById('search_wedding_products').classList.add('openBox');
    }
  }

  //--------------------------------------------------------------------------

  searchEveningProducts(event) {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
      const searchTerm = this.inputTarget.value.trim()

      const formData = new FormData();
      formData.append('search_term', searchTerm);

      let search_evening_path = window.location.origin + "/admin/home/search_evening"
      fetch(search_evening_path, {
        method: 'POST',
        headers: {
          'X-CSRF-Token': csrfToken,
          'Accept': 'text/vnd.turbo-stream.html'
        },
        body: formData
      })
      .then(response => response.text())
      .then(turboStream => {
        document.querySelector('#search_evening_products').innerHTML = turboStream;
      })
    }, 500)
  }

  inputEveningFocusout(event) {
    if (document.getElementById('search_evening_controller').contains(event.relatedTarget)) {
      return;
    }

    document.getElementById('search_evening_products').classList.remove('openBox');
  }

  inputEveningOnfocus() {
    const inp = document.getElementById('evening-search-input');

    if (inp.value.length > 0) {
      document.getElementById('search_evening_products').classList.add('openBox');
    }
  }

  //--------------------------------------------------------------------------

  searchKyAtelierProducts(event) {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
      const searchTerm = this.inputTarget.value.trim()

      const formData = new FormData();
      formData.append('search_term', searchTerm);

      let search_ky_atelier_path = window.location.origin + "/admin/home/search_ky_atelier"
      fetch(search_ky_atelier_path, {
        method: 'POST',
        headers: {
          'X-CSRF-Token': csrfToken,
          'Accept': 'text/vnd.turbo-stream.html'
        },
        body: formData
      })
      .then(response => response.text())
      .then(turboStream => {
        document.querySelector('#search_ky_atelier_products').innerHTML = turboStream;
      })
    }, 500)
  }

  inputKyAtelierFocusout(event) {
    if (document.getElementById('search_ky_atelier_controller').contains(event.relatedTarget)) {
      return;
    }

    document.getElementById('search_ky_atelier_products').classList.remove('openBox');
  }

  inputKyAtelierOnfocus() {
    const inp = document.getElementById('ky_atelier-search-input');

    if (inp.value.length > 0) {
      document.getElementById('search_ky_atelier_products').classList.add('openBox');
    }
  }

  //--------------------------------------------------------------------------

  searchLingerieProducts(event) {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
      const searchTerm = this.inputTarget.value.trim()

      const formData = new FormData();
      formData.append('search_term', searchTerm);

      let search_lingerie_path = window.location.origin + "/admin/home/search_lingerie"
      fetch(search_lingerie_path, {
        method: 'POST',
        headers: {
          'X-CSRF-Token': csrfToken,
          'Accept': 'text/vnd.turbo-stream.html'
        },
        body: formData
      })
      .then(response => response.text())
      .then(turboStream => {
        document.querySelector('#search_lingerie_products').innerHTML = turboStream;
      })
    }, 500)
  }

  inputLingerieFocusout(event) {
    if (document.getElementById('search_lingerie_controller').contains(event.relatedTarget)) {
      return;
    }

    document.getElementById('search_lingerie_products').classList.remove('openBox');
  }

  inputLingerieOnfocus() {
    const inp = document.getElementById('lingerie-search-input');

    if (inp.value.length > 0) {
      document.getElementById('search_lingerie_products').classList.add('openBox');
    }
  }
}
