document.addEventListener("turbo:load", function(){
  var video = document.getElementById('video');

  if (video) {
    video.play().then(function() {
    }).catch(function(error) {
      console.error('Error playing video:', error);
    });
  }
});

window.onload = function() {
  var videoElement = document.getElementById('first-screen-video');
  var source = document.createElement('source');
  source.src = '/assets/home/first-screen-video-new.mov';
  source.type = 'video/mp4';
  videoElement?.appendChild(source);
  videoElement?.load();
};
