import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="picture-upload-helper"
export default class extends Controller {
  static targets = ["preview", "filePreview"];

  showPreview(event) {
    if (event.target.files.length < 0) return;
    this.filePreviewTarget.src               = URL.createObjectURL(event.target.files[0]);
    this.filePreviewTarget.style.display     = "block";
    this.previewTarget.style.backgroundImage = "none";
  }
}
