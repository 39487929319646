import { Application } from "@hotwired/stimulus"
import { Datepicker } from 'stimulus-datepicker'
import Sortable from "stimulus-sortable"
import SearchBoutiqueController from "./search_boutique_controller"
import DateRangePickerController from "./date_range_picker_controller"

const application = Application.start();
application.register('datepicker', Datepicker);
application.register('sortable', Sortable);
application.register('search-boutique', SearchBoutiqueController);
application.register('date-range-picker', DateRangePickerController)

application.locale = 'en';

// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application

export { application }
