import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input"]

  connect() {
    this.loadStylesheet("https://cdn.jsdelivr.net/gh/alumuko/vanilla-datetimerange-picker@latest/dist/vanilla-datetimerange-picker.css")
    this.loadScript("https://cdn.jsdelivr.net/momentjs/latest/moment.min.js")
      .then(() => this.loadScript("https://cdn.jsdelivr.net/gh/alumuko/vanilla-datetimerange-picker@latest/dist/vanilla-datetimerange-picker.js"))
      .then(() => this.initializeDateRangePicker())
  }

  loadScript(src) {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script')
      script.src = src
      script.onload = resolve
      script.onerror = reject
      document.head.appendChild(script)
    })
  }

  loadStylesheet(href) {
    return new Promise((resolve, reject) => {
      const link = document.createElement('link')
      link.rel = 'stylesheet'
      link.href = href
      link.onload = resolve
      link.onerror = reject
      document.head.appendChild(link)
    })
  }

  initializeDateRangePicker() {
    const input = this.inputTarget
    if (input) {
      new DateRangePicker(input, {
        autoApply: false,
        locale: {
          format: 'YYYY-MM-DD',
          applyLabel: 'Apply',
          cancelLabel: 'Cancel'
        }
      }, (start, end) => {
        input.value = start.format('YYYY-MM-DD') + ' - ' + end.format('YYYY-MM-DD')
        input.closest('form').submit()
      })
    }
  }
}
