import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["quantity", "comment", "size"];

  updateQuantity(event) {
    const lineItemId = event.params.index;
    const quantity = this.quantityTarget.value

    Rails.ajax({
      type: "PATCH",
      url: `/cart/line_items/${lineItemId}`,
      data: `target=quantity&data=${quantity}`,
      accept: 'text/vnd.turbo-stream.html',
      success: (data) => {
        document.querySelector(`#line_item_${lineItemId}`).innerHTML = data;
      },
      error: (error) => {
        console.error(error);
      }
    });
  }

  preorderUpdateQuantity(event) {
    const lineItemId = event.params.index;
    const quantity = this.quantityTarget.value

    Rails.ajax({
      type: "PUT",
      url: `/cart/line_items/${lineItemId}/preorder_update`,
      data: `target=quantity&data=${quantity}`,
      accept: 'text/vnd.turbo-stream.html',
      success: (data) => {
        document.querySelector(`#line_item_${lineItemId}`).innerHTML = data;
      },
      error: (error) => {
        console.error(error);
      }
    });
  }

  updateComment(event) {
    const lineItemId = event.params.index;
    const comment = this.commentTarget.value

    Rails.ajax({
      type: "PATCH",
      url: `/cart/line_items/${lineItemId}`,
      data: `target=comment&data=${comment}`,
      accept: 'text/vnd.turbo-stream.html',
      success: (data) => {
        document.querySelector(`#line_item_${lineItemId}`).innerHTML = data;
      },
      error: (error) => {
        console.error(error);
      }
    });
  }

  updateSize(event) {
    const lineItemId = event.params.index;
    const sizeId = this.sizeTarget.value

    Rails.ajax({
      type: "PATCH",
      url: `/cart/line_items/${lineItemId}`,
      data: `target=size_id&data=${sizeId}`,
      accept: 'text/vnd.turbo-stream.html',
      success: (data) => {
        document.querySelector(`#line_item_${lineItemId}`).innerHTML = data;
      },
      error: (error) => {
        console.error(error);
      }
    });
  }
}
